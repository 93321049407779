<template>
  <div class="container" v-if="entireProcessingFormObject.validFrom">
    <h3 id="edit_form_step_one_title">
      <span v-html="$t('processing_form_edit_step_one_milk_utilizer_control')" />
      - <span v-html="$t('processing_form_edit_step_one_period')" />

      {{ $formatChDate(entireProcessingFormObject.validFrom) }} -
      {{ $formatChDate(entireProcessingFormObject.validUntil) }}
    </h3>
    <v-col cols="12"> <h5 v-html="$t(generateCustomSubHeaderTextfield())"></h5></v-col>

    <div class="mb-4">
      <v-row v-for="(item, i) in processingFormValidationResult" :key="i">
        <v-col style="margin-top: 0.8rem" cols="1" sm="1">
          <p>{{ item[this.$getLangKey()] }}:</p>
        </v-col>
        <v-col cols="3">
          <v-chip pill :color="colors[item.processingFormValidationResult]" variant="flat">
            {{ item.validationCount }}
          </v-chip>
        </v-col>
      </v-row>
    </div>

    <v-expansion-panels :multiple="true" v-model="processingFormValidationGroups.panels">
      <v-expansion-panel v-for="(processingFormValidationGroup, i) in processingFormValidationGroups" :key="i" :multiple="true">
        <v-expansion-panel-title
          :id="'form_validation_group' + $t(processingFormValidationGroup.messageKey)"
          v-if="
            $privileges.has({
              path: '/processingForms/display',
              permission: 'full'
            }) ||
            (privileges.has({
              path: '/processingForms/display',
              permission: 'dairy'
            }) &&
              (processingFormValidationGroup.warningCount || processingFormValidationGroup.errorCount))
          "
        >
          <v-icon style="max-width: 2rem" :color="getColor(processingFormValidationGroup.warningCount, processingFormValidationGroup.errorCount)">
            {{ getIcon(processingFormValidationGroup.warningCount, processingFormValidationGroup.errorCount) }}
          </v-icon>
          {{ $t(processingFormValidationGroup.messageKey) }}
        </v-expansion-panel-title>

        <v-expansion-panel-text>
          <!-- subGroupDTO -->
          <v-expansion-panels :multiple="true" v-model="processingFormValidationGroup.subGroupDTOs.panels">
            <v-expansion-panel
              v-for="(subGroupDTO, ix) in processingFormValidationGroup.subGroupDTOs"
              :key="ix"
              :multiple="true"
              :id="'validation_group_sub_' + $t(subGroupDTO.messageKey)"
            >
              <v-expansion-panel-title
                v-if="
                  $privileges.has({
                    path: '/processingForms/display',
                    permission: 'full'
                  })
                "
              >
                <v-icon style="max-width: 2rem" :color="getColor(subGroupDTO.warningCount, subGroupDTO.errorCount)">
                  {{ getIcon(processingFormValidationGroup.warningCount, processingFormValidationGroup.errorCount) }}
                </v-icon>
                {{ $t(subGroupDTO.messageKey) }}
              </v-expansion-panel-title>

              <!-- processingFormValidation -->
              <v-expansion-panel-text>
                <v-expansion-panels :multiple="true">
                  <v-expansion-panel
                    v-for="(processingFormValidation, indx) in subGroupDTO.processingFormValidations"
                    :key="indx"
                    :multiple="true"
                    @click="setTreeRelevantRow(processingFormValidation.id)"
                  >
                    <v-expansion-panel-title>
                      <v-icon style="max-width: 2rem" :color="colors[processingFormValidation.result]">
                        {{ icons[processingFormValidation.result] }}
                      </v-icon>
                      {{ processingFormValidation.formValidationType[[this.$getLangKey()]] }},
                      {{ replaceMessageKeyString(processingFormValidation.messageKey, processingFormValidation) }}
                    </v-expansion-panel-title>
                    <v-expansion-panel-text>
                      <div v-if="treeRelevantRows[processingFormValidation.id]">
                        <ProcessingFormTable
                          :treeRelevantRows="treeRelevantRows[processingFormValidation.id]"
                          :formValidationGroup="processingFormValidation"
                        />
                      </div>
                    </v-expansion-panel-text>
                  </v-expansion-panel>
                </v-expansion-panels>
              </v-expansion-panel-text>
            </v-expansion-panel>
          </v-expansion-panels>
        </v-expansion-panel-text>
      </v-expansion-panel>
    </v-expansion-panels>
    <div class=""></div>
    <v-btn class="m-2" color="secondary" @click="goBack"><span v-html="$t('processing_form_edit_step_one_back')" /></v-btn>

    <v-btn class="m-2" color="secondary" @click="cancel"><span v-html="$t('processing_form_edit_step_one_cancel')" /></v-btn>

    <v-btn class="m-2" @click="goForward" color="primary"><span v-html="$t('processing_form_edit_step_one_forward')" /></v-btn>
  </div>
</template>

<script lang="ts">
import { format } from 'date-fns'
import { apiURL } from '../../../main'
import _ from 'lodash'
import ProcessingFormTable from '../../../components/processingFormTable.vue'
import { showError } from '@/services/axios'
import { CustomFormatter } from '@/views/translations/CustomFormatter'

import { defineComponent } from 'vue'
export default defineComponent({
  components: {
    ProcessingFormTable: ProcessingFormTable
  },
  data() {
    return {
      lodash: _,
      processingFormValidationGroups: [],
      treeRelevantRows: {},
      processingFormValidationResult: [],
      entireProcessingFormObject: {},
      colors: {
        OK: 'green',
        WARNING: 'yellow',
        ERROR: 'red'
      },
      icons: {
        OK: 'mdi-checkbox-marked-circle',
        WARNING: 'mdi-alert',
        ERROR: 'mdi-cancel'
      }
    }
  },
  methods: {
    replaceMessageKeyString(string, processingFormValidation) {
      let translatedString = this.$t(string)
      if (processingFormValidation.product) translatedString = translatedString.replace('$PRODUCTCODE', processingFormValidation.product.code)
      translatedString = translatedString.replace('$BLOCK', processingFormValidation.block)
      translatedString = translatedString.replace('$DIFFERENCE', processingFormValidation.outputAvgTotal)
      return translatedString
    },
    getColor(warningCount, errorCount) {
      if (errorCount) return this.colors.ERROR
      if (warningCount) return this.colors.WARNING
      return this.colors.OK
    },
    getIcon(warningCount, errorCount) {
      if (errorCount) return this.icons.ERROR
      if (warningCount) return this.icons.WARNING
      return this.icons.OK
    },
    async getProcessingFormValidationGroups() {
      try {
        const result = await this.axios.get(
          apiURL + '/processingForms/' + this.$route.params.id + '/validationGroups',
          {
            params: {
              processingFormId: this.$route.params.id
            }
          },
          { headers: { 'Content-Type': 'application/json' } }
        )
        if (await result) {
          result.data = this.setPanels(result.data)
          return result.data
        }
      } catch (e) {
        showError(e)
      }
    },
    async setTreeRelevantRow(validationId) {
      if (this.treeRelevantRows[validationId]) {
        // this row is allready loaded ..
        return
      }
      try {
        const result = await this.axios.get(apiURL + '/processingForms/' + this.$route.params.id + '/formValidation/' + validationId + '/treeRelevantRows', {
          headers: { 'Content-Type': 'application/json' }
        })

        this.treeRelevantRows[validationId] = result.data
      } catch (e) {
        showError(e)
      }
    },
    async getProcessingFormValidationResult() {
      try {
        const result = await this.axios.get(
          apiURL + '/processingForms/' + this.$route.params.id + '/validationSummary',
          {
            params: {
              processingFormId: this.$route.params.id
            }
          },
          { headers: { 'Content-Type': 'application/json' } }
        )
        if (await result) {
          return result.data
        }
      } catch (e) {
        showError(e)
      }
    },
    async getProcessingForm() {
      try {
        const result = await this.axios.get(
          apiURL + '/processingForms/' + this.$route.params.id,
          {
            id: this.$route.params.id
          },
          { headers: { 'Content-Type': 'application/json' } }
        )
        if (await result) {
          return result.data
        }
      } catch (e) {
        showError(e)
      }
    },
    async goForward() {
      this.$router
        .push({
          name: 'processingForms_edit_form_step_two',
          params: { id: this.$route.params.id }
        })
        .catch((e) => {
          /* duplicated route */
        })
    },
    goBack() {
      this.$router
        .push({
          name: 'processingForms_edit',
          params: { id: this.$route.params.id }
        })
        .catch((e) => {
          /* duplicated route */
        })
    },
    cancel() {
      this.$router
        .push({
          name: 'processingForms_read'
        })
        .catch((e) => {
          /* duplicated route */
        })
    },
    setPanels(processingFormValidationGroups) {
      // open mainpanels
      processingFormValidationGroups.panels = processingFormValidationGroups
        .filter(function (elem, indx) {
          elem.indx = indx
          return elem.warningCount || elem.errorCount
        })
        .map((elem) => elem.indx)

      // open panels in subGroupDto
      processingFormValidationGroups.forEach((processingFormValidationGroup) => {
        processingFormValidationGroup.subGroupDTOs.panels = processingFormValidationGroup.subGroupDTOs
          .filter(function (elem, indx) {
            elem.indx = indx
            return elem.warningCount || elem.errorCount
          })
          .map((elem) => elem.indx)
      })
      return processingFormValidationGroups
    },
    generateCustomSubHeaderTextfield() {
      const mbhId = this.entireProcessingFormObject?.dairyIdent ? this.entireProcessingFormObject?.dairyIdent : ''
      const mbhName1 = this.entireProcessingFormObject?.dairyName1 ? this.entireProcessingFormObject?.dairyName1 : ''
      const mbhName2 = this.entireProcessingFormObject?.dairyName2 ? this.entireProcessingFormObject?.dairyName2 : ''
      const formatter = new CustomFormatter()
      return formatter.interpolate(this.$t('processing_form_edit_subheader_step_one'), [mbhId, mbhName1, mbhName2])
    }
  },
  mounted: function () {
    this.$nextTick(async () => {
      this.entireProcessingFormObject = await this.getProcessingForm()
      this.processingFormValidationGroups = await this.getProcessingFormValidationGroups()
      this.processingFormValidationResult = await this.getProcessingFormValidationResult()
    })
  }
})
</script>
