<template>
  <v-table density="compact">
    <template v-for="(formPart, fpindex) of treeRelevantRows" :key="'fp' + fpindex">
      <thead>
        <tr :id="'fp' + fpindex" v-bind:key="'fp' + fpindex" class="formPart">
          <th colspan="4">{{ formPart.ident }} {{ formPart[this.$getLangKey()] }}</th>
        </tr>
      </thead>
      <template v-for="(fpgroup, fpgindex) of formPart.groups" :key="'fp' + fpindex + 'fpg' + fpgindex">
        <tbody>
          <tr :id="'fp' + fpindex + 'fpg' + fpgindex" v-bind:key="'fp' + fpindex + 'fpg' + fpgindex" class="fpgroup">
            <td colspan="2">{{ fpgroup.formPartIdent }}/{{ fpgroup.ident }}/{{ fpgroup.block }}</td>
            <td colspan="2">
              {{ fpgroup[this.$getLangKey()] }}
            </td>
            <td v-if="showDebug()" colspan="1" v-html="$t('processing_form_table_status')"></td>
            <td v-if="showDebug()" colspan="1" v-html="$t('processing_form_table_animal_type')"></td>
            <td colspan="1" v-html="$t('processing_form_table_milklabel')"></td>
            <!--            Rohstoff in kg-->
            <td colspan="1" class="align-right" v-if="formValidationGroup.formValidationType.logic !== 'CHEESE_WHEEL'">
              <label for="quantity1">{{ fpgroup['label1' + $route.params.upLang] }}</label>
            </td>
            <!--            Rohstoff in kg-->
            <td colspan="1" class="align-right" v-if="formValidationGroup.formValidationType.logic !== 'CHEESE_WHEEL'">
              <label for="quantity2">{{ fpgroup['label2' + $route.params.upLang] }}</label>
            </td>

            <td v-if="fpgroup.askFat" colspan="1" class="align-right">
              <label for="text1" v-html="$t('processing_form_table_fat')"></label>
            </td>

            <td v-else colspan="1">
              <label for="text1"></label>
            </td>
            <!--            Kessimilch Faktor-->
            <td
              v-if="formValidationGroup.formValidationType.logic === 'CHEESE_YIELD' || formValidationGroup.formValidationType.logic === 'CHEESE_WHEEL'"
              colspan="1"
              class="align-right"
            >
              <label for="text1" v-html="$t('processing_form_table_kessi_factor')"></label>
            </td>
            <!--            Menge Eingang / Verarbeitungsoutput-->
            <td
              v-if="
                formValidationGroup.formValidationType.logic !== 'PROTEIN_BALANCE' &&
                formValidationGroup.formValidationType.logic !== 'FAT_BALANCE' &&
                formValidationGroup.formValidationType.logic !== 'CHEESE_YIELD' &&
                formValidationGroup.formValidationType.logic !== 'CHEESE_WHEEL'
              "
              colspan="1"
              class="align-right"
            >
              <label for="text1"
                ><span v-html="$t('processing_form_table_entry_quantity')" /> / <span v-html="$t('processing_form_table_processing_output')"
              /></label>
            </td>
            <!--            Menge Ausgang / Verarbeitungsinput-->
            <td
              v-if="
                formValidationGroup.formValidationType.logic !== 'CHEESE_YIELD' &&
                formValidationGroup.formValidationType.logic !== 'PROTEIN_BALANCE' &&
                formValidationGroup.formValidationType.logic !== 'FAT_BALANCE' &&
                formValidationGroup.formValidationType.logic !== 'CHEESE_WHEEL'
              "
              colspan="1"
              class="align-right"
            >
              <label for="text1"
                ><span v-html="$t('processing_form_table_output_quantity')" /> / <span v-html="$t('processing_form_table_processing_entry')"
              /></label>
            </td>
            <!--            Milch in kg-->
            <td v-if="formValidationGroup.formValidationType.logic === 'CHEESE_YIELD'" colspan="1" class="align-right">
              <label for="text1" v-html="$t('processing_form_table_milk_in_kg')"></label>
            </td>
            <!--            Erzeugnis in kg-->
            <td
              v-if="formValidationGroup.formValidationType.logic === 'CHEESE_YIELD' || formValidationGroup.formValidationType.logic === 'CHEESE_WHEEL'"
              colspan="1"
              class="align-right"
            >
              <label for="text1" v-html="$t('processing_form_table_yield_in_kg')"></label>
            </td>
            <!--            Laibzahl-->
            <td v-if="formValidationGroup.formValidationType.logic === 'CHEESE_WHEEL'" colspan="1" class="align-right">
              <label v-html="$t('PROCESSING_VALIDATION_SUBGROUP_CHEESE_WHEEL')"></label>
            </td>
            <td colspan="2" v-if="formValidationGroup.formValidationType.logic === 'FAT_BALANCE'" class="align-right">
              <label><span v-html="$t('processing_form_table_amount_value')" /> [%] (min/max)</label>
            </td>

            <td colspan="2" v-if="formValidationGroup.formValidationType.logic === 'FAT_BALANCE'" class="align-right">
              <label><span v-html="$t('processing_form_table_fat_input')" /> [kg] (min/max)</label>
            </td>

            <td colspan="2" v-if="formValidationGroup.formValidationType.logic === 'FAT_BALANCE'" class="align-right">
              <label><span v-html="$t('processing_form_table_fat_output')" /> [kg] (min/max)</label>
            </td>

            <td colspan="2" v-if="formValidationGroup.formValidationType.logic === 'PROTEIN_BALANCE'" class="align-right">
              <label><span v-html="$t('processing_form_table_amount_value')" /> [%] (min/max)</label>
            </td>

            <td colspan="2" v-if="formValidationGroup.formValidationType.logic === 'PROTEIN_BALANCE'" class="align-right">
              <label><span v-html="$t('processing_form_table_protein_input')" /> [kg] (min/max)</label>
            </td>

            <td colspan="2" v-if="formValidationGroup.formValidationType.logic === 'PROTEIN_BALANCE'" class="align-right">
              <label><span v-html="$t('processing_form_table_protein_output')" /> [kg] (min/max)</label>
            </td>
          </tr>
        </tbody>
        <template v-for="(gRow, gindx) of fpgroup.rows" :key="'fp' + fpindex + 'fpg' + fpgindex + 'grow' + gRow.id + gindx">
          <tbody>
            <tr v-bind:key="'fp' + fpindex + 'fpg' + fpgindex + 'grow' + gRow.id + gindx" :id="'fp' + fpindex + 'fpg' + fpgindex + 'grow' + gRow.id + gindx">
              <template v-if="gRow.versionNo === 1">
                <td>{{ gRow.rowTypeIdent }}</td>
                <td>{{ gRow.productCode }}</td>
                <td>{{ gRow['rowType' + this.$getUpLangKey()] }}</td>
                <td>{{ gRow['product' + this.$getUpLangKey()] }}</td>
                <td v-if="showDebug()">
                  {{ gRow.animalTypes[0][this.$getLangKey()] }}
                </td>
              </template>
              <td colspan="4" v-else>
                <span v-html="$t('processing_form_table_correction_course')" />:
                {{ gRow.versionNo - 1 }}
                <span v-html="$t('processing_form_table_difference')" />:
                {{ gRow.quantityCalculated }}
              </td>
              <td v-if="showDebug()">{{ gRow.applicationFormStatusIdent }}</td>
              <td>
                {{ translateMilkLabel(gRow.milkLabel) }}
              </td>
              <td v-if="gRow.rowTypeColumnNr === 1 && formValidationGroup.formValidationType.logic !== 'CHEESE_WHEEL'" colspan="1">
                <p dbm-number>{{ gRow.quantity }}</p>
              </td>
              <td colspan="1" v-if="gRow.rowTypeColumnNr === 1">
                <p></p>
              </td>

              <td colspan="1" v-if="gRow.rowTypeColumnNr === 2 && formValidationGroup.formValidationType.logic !== 'CHEESE_WHEEL'">
                <p></p>
              </td>
              <td colspan="1" v-if="gRow.rowTypeColumnNr === 2 && formValidationGroup.formValidationType.logic !== 'CHEESE_WHEEL'">
                <p dbm-number>{{ gRow.quantity }}</p>
              </td>

              <td v-if="gRow.productAskFat.includes('YES')" colspan="1">
                <p dbm-number>{{ gRow.fat }}</p>
              </td>

              <td v-else colspan="1">
                <p></p>
              </td>
              <!--              Kessimilch Faktor Grüngewicht-->
              <td v-if="formValidationGroup.formValidationType.logic === 'CHEESE_YIELD'" colspan="1">
                <p dbm-number v-if="gRow.fatConversionFactor">
                  {{ $formatNumber(gRow.fatConversionFactor, 2) }}
                </p>
              </td>
              <!--              Kessimilch Faktor Laibgewicht-->
              <td v-if="formValidationGroup.formValidationType.logic === 'CHEESE_WHEEL'" colspan="1">
                <p dbm-number v-if="gRow.rowValidationFactor">
                  {{ $formatNumber(gRow.rowValidationFactor, 2) }}
                </p>
              </td>
              <!--              Menge Eingang / Verarbeitungsoutput / Erzeugnis in kg (Veräster Käse in kg)-->
              <td
                v-if="formValidationGroup.formValidationType.logic !== 'PROTEIN_BALANCE' && formValidationGroup.formValidationType.logic !== 'FAT_BALANCE'"
                colspan="1"
              >
                <p dbm-number>{{ gRow.rowValidationInput }}</p>
              </td>
              <!--              Menge Ausgang / Verarbeitungsinput / Laibzahl-->
              <td
                v-if="formValidationGroup.formValidationType.logic !== 'PROTEIN_BALANCE' && formValidationGroup.formValidationType.logic !== 'FAT_BALANCE'"
                colspan="1"
              >
                <p dbm-number>{{ gRow.rowValidationOutput }}</p>
              </td>

              <td colspan="1" v-if="formValidationGroup.formValidationType.logic === 'FAT_BALANCE'">
                <p dbm-number>{{ gRow.fatMin }}</p>
              </td>

              <td colspan="1" v-if="formValidationGroup.formValidationType.logic === 'FAT_BALANCE'">
                <p dbm-number>{{ gRow.fatMax }}</p>
              </td>

              <td colspan="1" v-if="formValidationGroup.formValidationType.logic === 'FAT_BALANCE'">
                <p dbm-number>{{ gRow.rowValidationInputMin }}</p>
              </td>

              <td colspan="1" v-if="formValidationGroup.formValidationType.logic === 'FAT_BALANCE'">
                <p dbm-number>{{ gRow.rowValidationInputMax }}</p>
              </td>

              <td colspan="1" v-if="formValidationGroup.formValidationType.logic === 'FAT_BALANCE'">
                <p dbm-number>{{ gRow.rowValidationOutputMin }}</p>
              </td>

              <td colspan="1" v-if="formValidationGroup.formValidationType.logic === 'FAT_BALANCE'">
                <p dbm-number>{{ gRow.rowValidationOutputMax }}</p>
              </td>

              <td colspan="1" v-if="formValidationGroup.formValidationType.logic === 'PROTEIN_BALANCE'">
                <p dbm-number>{{ gRow.proteinMin }}</p>
              </td>

              <td colspan="1" v-if="formValidationGroup.formValidationType.logic === 'PROTEIN_BALANCE'">
                <p dbm-number>{{ gRow.proteinMax }}</p>
              </td>
              <td colspan="1" v-if="formValidationGroup.formValidationType.logic === 'PROTEIN_BALANCE'">
                <p dbm-number>{{ gRow.rowValidationInputMin }}</p>
              </td>

              <td colspan="1" v-if="formValidationGroup.formValidationType.logic === 'PROTEIN_BALANCE'">
                <p dbm-number>{{ gRow.rowValidationInputMax }}</p>
              </td>

              <td colspan="1" v-if="formValidationGroup.formValidationType.logic === 'PROTEIN_BALANCE'">
                <p dbm-number>{{ gRow.rowValidationOutputMin }}</p>
              </td>

              <td colspan="1" v-if="formValidationGroup.formValidationType.logic === 'PROTEIN_BALANCE'">
                <p dbm-number>{{ gRow.rowValidationOutputMax }}</p>
              </td>
            </tr>
            <template
              v-if="
                formValidationGroup && treeRelevantRows.length - 1 === fpindex && formPart.groups.length - 1 === fpgindex && fpgroup.rows.length - 1 === gindx
              "
            >
              <tr
                :id="'total' + fpgindex"
                v-bind:key="'total' + fpgindex"
                v-if="formValidationGroup.formValidationType.logic === 'PROTEIN_BALANCE' || formValidationGroup.formValidationType.logic === 'FAT_BALANCE'"
              >
                <td :colspan="calculateColSpan(formValidationGroup.formValidationType.logic)">
                  <p>
                    <span v-html="$t('total')" />
                    {{ formValidationGroup.formValidationType.logic === 'PROTEIN_BALANCE' ? 'Protein' : 'Fett' }}
                    Mengen:
                  </p>
                </td>
                <td colspan="1">
                  <p dbm-number>{{ formValidationGroup.inputMinTotal }}</p>
                </td>
                <td colspan="1">
                  <p dbm-number>{{ formValidationGroup.inputMaxTotal }}</p>
                </td>
                <td colspan="1">
                  <p dbm-number>{{ formValidationGroup.outputMinTotal }}</p>
                </td>
                <td colspan="1">
                  <p dbm-number>{{ formValidationGroup.outputMaxTotal }}</p>
                </td>
              </tr>

              <tr
                :id="'total' + fpgindex"
                v-bind:key="'total' + fpgindex"
                v-if="formValidationGroup.formValidationType.logic !== 'PROTEIN_BALANCE' && formValidationGroup.formValidationType.logic !== 'FAT_BALANCE'"
              >
                <td :colspan="calculateColSpan(formValidationGroup.formValidationType.logic)">
                  <p><span v-html="$t('total')" />:</p>
                </td>
                <td colspan="2">
                  <p dbm-number>{{ formValidationGroup.inputTotal }}</p>
                </td>
                <td colspan="1">
                  <p dbm-number>{{ formValidationGroup.outputTotal }}</p>
                </td>
              </tr>
              <!--              Grüngewicht errechnet-->
              <tr :id="'greenweight' + fpgindex" v-if="formValidationGroup.formValidationType.logic === 'CHEESE_YIELD'" v-bind:key="'greenweight' + fpgindex">
                <td colspan="12">
                  <p>
                    {{ $t('processing_form_table_green_weight_calculated') + ': ' + $formatNumber(calculatedGreenWeight, 2) }}
                    %
                  </p>
                </td>
              </tr>
              <!--              Grüngewicht min/max-->
              <tr :id="'total' + fpgindex" v-if="formValidationGroup.formValidationType.logic === 'CHEESE_YIELD'" v-bind:key="'total' + fpgindex">
                <td colspan="2">
                  <p>
                    {{ $t('processing_form_table_green_weight_min') + ': ' + $formatNumber(formValidationGroup.product.greenWeightMin, 2) }}
                    %
                  </p>
                </td>
                <td colspan="2">
                  <p>
                    {{ $t('processing_form_table_green_weight_current') + ': ' + $formatNumber(calculatedGreenWeight, 2) }}
                    %
                  </p>
                </td>
                <td colspan="2">
                  <p>
                    {{ $t('processing_form_table_green_weight_max') + ': ' + $formatNumber(formValidationGroup.product.greenWeightMax, 2) }}
                    %
                  </p>
                </td>
              </tr>
              <!--              Laibgewicht min/max/errechnet-->
              <tr :id="'total' + fpgindex" v-if="formValidationGroup.formValidationType.logic === 'CHEESE_WHEEL'" v-bind:key="'total' + fpgindex">
                <td colspan="2">
                  <p>
                    {{ $t('processing_form_table_cheese_wheel_min') + ': ' + $formatNumber(formValidationGroup.product.loafWeightMin, 2) }}
                    kg
                  </p>
                </td>
                <td colspan="2">
                  <p>
                    {{ $t('processing_form_table_cheese_wheel_max') + ': ' + $formatNumber(formValidationGroup.product.loafWeightMax, 2) }}
                    kg
                  </p>
                </td>
                <td colspan="2">
                  <p>
                    {{ $t('processing_form_table_cheese_wheel_calculated') + ': ' + $formatNumber(calculatedWheelWeight(), 2) }}
                    kg
                  </p>
                </td>
              </tr>
            </template>
          </tbody>
        </template>
      </template>
    </template>
  </v-table>
</template>

<script lang="ts">
import { useSettingsStore } from '@/store/SettingsStore'
import { useMilkLabelsStore } from '@/store/enums/MilkLabelsStore'

import { defineComponent } from 'vue'

export default defineComponent({
  props: ['treeRelevantRows', 'formValidationGroup'],
  computed: {
    milkLabels() {
      return useMilkLabelsStore().milkLabels
    },
    calculatedGreenWeight() {
      return (this.formValidationGroup.outputTotal * 100) / this.formValidationGroup.inputTotal
    }
  },
  methods: {
    calculateColSpan(logic) {
      if (logic === 'EXPANDED_SUM_EXAMINATION') return 7
      if (logic === 'CROSS_CHECK_PRODUCTION_FORM') return 7
      if (logic === 'SINGLE_PRODUCT_SUM_CHECK') return 7
      if (logic === 'CHEESE_YIELD') return 8
      if (logic === 'PROTEIN_BALANCE') return 10
      if (logic === 'FAT_BALANCE') return 10
      if (logic === 'CHEESE_WHEEL') return 6
    },
    showDebug() {
      let settingsStore = useSettingsStore()
      return settingsStore.getDebug
    },
    translateMilkLabel(labelName) {
      let labels = this.milkLabels.filter((e) => e.name == labelName)
      return Array.isArray(labels) && labels.length ? labels[0][this.$getLangKey()] : 'none'
    },
    calculatedWheelWeight() {
      return this.formValidationGroup.inputTotal / this.formValidationGroup.outputTotal
    }
  },
  beforeMount() {
    useMilkLabelsStore().fetchMilkLabels()
  }
})
</script>

<style lang="scss" scoped>
.processingFormHeaderContainer {
  margin-top: 1rem;
  margin-bottom: 3rem;
}

.list-group {
  text-align: left;
}

.v-input__slot {
  align-items: center;
  justify-content: center;
}

.align-right {
  text-align: right;
}
</style>
